.App {
  background-image: url('./images/background.jpg');
  padding: 25px;
  color: #4b4e56;
  text-align: center;
}

.line {
  border-bottom: 3px solid #4b4e56a6;
  margin-bottom: 15px;
}

#logo {
  width: 75%;
}

#lower_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

#spreadable {
  width: 300px;
  padding: 25px;
}

#blurb {
  text-align: center;
  font-size: 30px;
  padding: 0 25px;
  margin: 0;
}

@media only screen and (min-width: 960px) {
  #lower_section {
    flex-direction: row;
  }
}